var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "pb-1"
  }, [_c('h4', {
    staticClass: "card-title font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('filter')) + " ")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "warning",
      "disabled": _vm.emptyFilter
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('fetch-data');
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "SearchIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('search')) + " ")], 1)]), _c('b-button', {
    attrs: {
      "variant": "danger",
      "disabled": _vm.emptyFilter
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('reset');
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "XOctagonIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('clear')) + " ")], 1)])], 1)]), _c('b-card-body', {
    staticClass: "py-0"
  }, [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('createdBy'),
      "label-for": "created-by"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "created-by-select",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.createdByOptions,
      "label": "firstName",
      "searchable": "",
      "clearable": false,
      "filterable": false,
      "value": _vm.createdByFilter,
      "loading": _vm.isLoading,
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val;
      }
    },
    on: {
      "open": _vm.handleOpenCreatedBy,
      "search": _vm.handleSearchCreatedBy,
      "input": function input(val) {
        return _vm.$emit('update:createdByFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref) {
        var firstName = _ref.firstName,
          lastName = _ref.lastName,
          type = _ref.type;
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " ")]), _c('b-badge', {
          staticClass: "line-height-condensed mr-1",
          attrs: {
            "variant": _vm.resolveEmployeeTypeVariant(type)
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(type, 'value', 'label'))) + " ")])], 1)];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref2) {
        var loading = _ref2.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "option",
      fn: function fn(_ref3) {
        var firstName = _ref3.firstName,
          lastName = _ref3.lastName,
          type = _ref3.type;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "9"
          }
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " ")])]), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": _vm.resolveEmployeeTypeVariant(type)
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveEmployeeType(type, 'value', 'label'))) + " ")])])], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }