var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showImportModal ? _c('customers-import-modal', {
    attrs: {
      "show-import-modal": _vm.showImportModal
    },
    on: {
      "update:showImportModal": function updateShowImportModal($event) {
        _vm.showImportModal = $event;
      },
      "update:show-import-modal": function updateShowImportModal($event) {
        _vm.showImportModal = $event;
      },
      "fetch-data": _vm.refetchData
    }
  }) : _vm._e(), _c('CustomersListFilter', {
    attrs: {
      "created-by-filter": _vm.createdByFilter,
      "empty-filter": _vm.isEmptyFilter
    },
    on: {
      "update:createdByFilter": function updateCreatedByFilter($event) {
        _vm.createdByFilter = $event;
      },
      "update:created-by-filter": function updateCreatedByFilter($event) {
        _vm.createdByFilter = $event;
      },
      "fetch-data": _vm.refetchData,
      "reset": _vm.clearFilter
    }
  }), _c('b-card', {
    staticClass: "mb-0",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "mx-2 my-1"
  }, [_c('b-row', {
    staticClass: "justify-content-md-between flex-md-nowrap",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "px-0 mr-md-1",
    attrs: {
      "cols": "4",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.sizePerPageLgOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }, [_c('span', {
    staticClass: "text-muted ml-50"
  }, [_vm._v(_vm._s(_vm.$t('customer.textShowCustomer')))])])], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end px-50",
    attrs: {
      "cols": "8",
      "md": "auto",
      "order-md": "3"
    }
  }, [_c('div', {
    staticClass: "d-none d-lg-flex"
  }, [_c('b-button', {
    staticClass: "mr-1 px-lg-1 px-md-75",
    attrs: {
      "disabled": !_vm.canAccess('customer.createCustomer'),
      "variant": "info"
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'apps-customers-add'
        });
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('customer.textBtnCreate')) + " ")], 1)]), _c('b-button', {
    staticClass: "mr-1 px-lg-1 px-md-75",
    attrs: {
      "variant": "info",
      "disabled": !_vm.canAccess('customer.exportCustomer')
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "DownloadIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('export')) + " ")], 1)])], 1), _c('div', {
    staticClass: "d-block d-lg-none"
  }, [_c('b-dropdown', {
    staticClass: "m-lg-2",
    attrs: {
      "variant": "info",
      "boundary": "window",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "MenuIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "pl-50"
        }, [_vm._v(_vm._s(_vm.$t('customer.moreDropdown.title')))])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.canAccess('customer.createCustomer')
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'apps-customers-add'
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.moreDropdown.add')) + " ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.canAccess('customer.exportCustomer')
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.moreDropdown.export')) + " ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.activeDelete() || !_vm.canAccess('customer.bulkDeleteCustomer')
    },
    on: {
      "click": function click($event) {
        return _vm.confirmDelete();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.moreDropdown.delete')) + " ")])], 1)], 1)]), _c('b-col', {
    staticClass: "mt-1 mt-md-0 px-50 flex-grow-1",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('b-input-group', {
    staticClass: "w-100",
    attrs: {
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center border rounded-left px-1 bg-light-info"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SearchIcon",
            "size": "20"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": _vm.$t('customer.placeholderSearch'),
      "value": _vm.searchTextFilter,
      "reduce": function reduce(val) {
        return val.value;
      },
      "maxlength": "255",
      "debounce": "500",
      "trim": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:searchTextFilter', val);
      }
    },
    model: {
      value: _vm.searchTextFilter,
      callback: function callback($$v) {
        _vm.searchTextFilter = $$v;
      },
      expression: "searchTextFilter"
    }
  })], 1)], 1)])], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refCustomerListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "50vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchCustomers,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": "No matching records found",
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "no-border-collapse": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("customer.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(code)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info",
          attrs: {
            "disabled": !_vm.canAccess('customer.detailCustomer'),
            "to": {
              name: 'apps-customers-edit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('span', {
          staticClass: "align-text-top text-capitalize font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.code) + " ")])])], 1)];
      }
    }, {
      key: "cell(information)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-avatar', {
                attrs: {
                  "size": "70px",
                  "src": data.item.photo,
                  "text": _vm.avatarText("".concat(data.item.lastName, " ").concat(data.item.firstName)),
                  "variant": "light-info"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex flex-column justify-content-center align-items-start"
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(data.item.lastName) + " " + _vm._s(data.item.firstName) + " ")]), _c('small', {
          staticClass: "d-flex align-items-center"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "MailIcon"
          }
        }), _c('span', {}, [_vm._v(_vm._s(data.item.emailAddress) + " ")])], 1), _c('small', {
          staticClass: "d-flex align-items-center"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "PhoneCallIcon"
          }
        }), _c('span', {}, [_vm._v(_vm._s(data.item.phoneNumber) + " ")])], 1), data.item.birthDay ? _c('small', {
          staticClass: "d-flex align-items-center"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "GiftIcon"
          }
        }), _c('span', {}, [_vm._v(_vm._s(_vm.formatDateMonth(data.item.birthDay) || "No Information") + " ")])], 1) : _vm._e()])])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.createdAt)) + " ")]), _c('small', [_vm._v(" " + _vm._s(data.item.createdBy ? "".concat(data.item.createdBy.firstName, " ").concat(data.item.createdBy.lastName) : ''))])])], 1)];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.updatedAt)) + " ")]), _c('small', [_vm._v(" " + _vm._s(data.item.updatedBy ? "".concat(data.item.updatedBy.firstName, " ").concat(data.item.updatedBy.lastName) : ''))])])], 1)];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "mx-2 mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "\n            d-flex\n            align-items-center\n            justify-content-center\n          ",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')))])]), _c('b-col', {
    staticClass: "\n            d-flex\n            align-items-center\n            justify-content-center\n          ",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-md-0",
    attrs: {
      "total-rows": _vm.totalCustomers,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }