<template>
  <div>
    <!--LOAD FILE FAILED-->
    <div
      v-if="isFileFailed"
      class="box bg-light d-flex flex-column justify-content-center align-items-center"
    >
      <div>
        <h4>
          {{ $t('loadFailText_1') }} <em class="text-muted">{{ fileName }}</em> {{ $t('loadFailText_2') }}
        </h4>
        <span>{{ importError }}</span>
      </div>
      <b-button
        variant="outline-info"
        class="mt-75"
        @click="$emit('reset')"
      >
        {{ $t('tryAgain') }}
      </b-button>
    </div>
    <!--IMPORT SUCCESS-->
    <div
      v-if="isImportSuccess"
      class="box d-flex flex-column justify-content-center align-items-center"
    >
      <h4>
        {{ $t('importCompleted') }}
      </h4>
      <div class="w-100 px-3">
        <b-row class="py-50">
          <b-col
            cols="12"
            md="2"
            class="d-flex justify-content-center"
          >
            <feather-icon
              icon="CheckIcon"
              size="32"
              class="d-block text-success"
            />
          </b-col>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center"
          >
            <span>{{ importResponse.insertedItems ? importResponse.insertedItems.length : 0 }} {{ $t('importDoneText') }}</span>
          </b-col>
        </b-row>
        <b-row
          v-if="(importResponse.failedItems.length > 0)"
        >
          <b-col
            cols="12"
            md="2"
            class="d-flex justify-content-center"
          >
            <feather-icon
              icon="XIcon"
              size="32"
              class="d-block text-danger"
            />
          </b-col>
          <b-col
            cols="12"
            md="10"
            class="d-flex flex-column justify-content-center"
          >
            <span>{{ importResponse.failedItems ? importResponse.failedItems.length : 0 }} {{ $t('importFailText') }}</span>
            <b-table
              :items="importResponse.failedItems"
              class="mt-50"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <!--IMPORT FAILED-->
    <div
      v-if="isImportFailed"
      class="box d-flex flex-column justify-content-center align-items-center"
    >
      <h4>
        {{ $t('importFailed') }}
      </h4>
      <span>{{ importError }}</span>
      <b-button
        variant="outline-info"
        class="mt-75"
        @click="$emit('reset')"
      >
        {{ $t('tryAgain') }}
      </b-button>
    </div>
    <!-- SECTION: ACTION -->
    <div>
      <div class="w-full m-50 d-flex justify-content-between align-items-center">
        <div>
          <b-button
            v-if="!isImportSuccess"
            variant="secondary"
            @click="$emit('hide-modal')"
          >
            {{ $t('cancel') }}
          </b-button>
          <p
            v-else-if="isImportSuccess"
            class="my-0"
          >
            {{ $t('importSuccess_1') }}
            <em
              class="text-primary cursor-pointer"
              @click.prevent="downloadRecentlyImportedFileHandle"
            >
              {{ $t('importSuccess_2') }}
            </em>
            {{ $t('importSuccess_3') }}
          </p>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <b-button
            v-if="isImportSuccess"
            variant="primary"
            @click="$emit('hide-modal')"
          >
            {{ $t('done') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCol, BRow, BTable, BButton,
} from 'bootstrap-vue'

import useCustomerHandle from '@customer/useCustomerHandle'

export default {
  components: {
    BCol, BRow, BTable, BButton,
  },
  props: {
    isFileFailed: {
      type: Boolean,
      default: false,
    },
    isImportSuccess: {
      type: Boolean,
      default: false,
    },
    isImportFailed: {
      type: Boolean,
      default: false,
    },
    importResponse: {
      type: Object,
      default: null,
    },
    importError: {
      type: [Object, String],
      default: null,
    },
    fileName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { exportCustomers } = useCustomerHandle()

    function downloadRecentlyImportedFileHandle() {
      const { insertedItems } = props.importResponse
      const ids = insertedItems.map(item => item.id)
      if (insertedItems.length) {
        // show modal confirm download recently file import employee
        const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.downloadRecentlyFile') } })
        this.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: this.$t('modal.confirm'),
            size: 'sm',
            okVariant: 'info',
            okTitle: this.$t('modal.yes'),
            cancelTitle: this.$t('modal.no'),
            cancelVariant: 'outline-danger',
            hideHeaderClose: true,
            centered: true,
          })
        // call export ids
          .then(value => {
            if (value) {
              exportCustomers(ids)
            }
          })
          .catch(error => {
            console.error({ error })
          })
      }
    }
    return {
      downloadRecentlyImportedFileHandle,
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    padding: 30px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    border-radius: 4px;
  }
</style>
